import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SocialLink = ({ itemClass, href, icon }) => {
  return (
    <li>
      <a href={href} target="_blank" rel="noreferrer" className={itemClass}>
        <FontAwesomeIcon icon={icon} />
      </a>
    </li>
  );
};
export default SocialLink;

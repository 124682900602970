import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./NavBar.css";
import { Spiral as Hamburger } from "hamburger-react";
import LangSelect from "./LangSelect";
import { useTranslation } from "react-i18next";
import CookieConsent from "react-cookie-consent";

function NavBar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const { t } = useTranslation();

  return (
    <>
      <nav className={click ? "navbar" : "navbar active"}>
      
          <div className="logo-container">
            <NavLink className="nav-logo" exact="true" to="/">
                Residencial Rolando
            </NavLink>
          </div>

            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item">
                <NavLink
                  exact="true"
                  to="/"
                  activeclassname="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  {t("navLinkHome")}
                </NavLink>
              </li>
              
              <li className="nav-item">
                <NavLink
                  exact="true"
                  to="/rooms"
                  activeclassname="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  {t("navLinkRooms")}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  exact="true"
                  to="/contact"
                  activeclassname="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  {t("navLinkContact")}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  exact="true"
                  to="/about"
                  activeclassname="active"
                  className="nav-links"
                  onClick={handleClick}
                >
                  {t("navLinkAbout")}
                </NavLink>
              </li>
              <li className="nav-item">
                <LangSelect parentClick={handleClick} />
              </li>
            </ul>
          <div className="nav-icon" onClick={handleClick}>
            <Hamburger toggled={click} />
          </div>
      </nav>
      <CookieConsent buttonText={t("cookieConsentButton")} buttonStyle={{ background: "var(--clr-primary-4)", color: "white" }}>{t("cookieConsentMessage")}{" "}Más info, <span><Link to="/cookies">pulsa aquí</Link></span></CookieConsent>
    </>
  );
}

export default NavBar;

// styles
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "../src/components/NavBar.css";

// Router
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";

// import the library
import { library } from "@fortawesome/fontawesome-svg-core";

// import your icons
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

import Gecko from "./components/Gecko";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./components/Home";
import About from "./components/About";
import Rooms from "./components/Rooms";
import Contact from "./components/Contact";
import Privacidad from "./components/ImpPrivacidad";
import AvisoLegal from "./components/ImpAvisoLegal";
import Reserva from "./components/ImpReserva";
import Cookies from "./components/ImpCookies";
import { Toaster } from "react-hot-toast";
/* import NotFound from "./components/NotFound"; */
import RoomDetail from "./components/RoomDetail";
import { useLayoutEffect } from "react";

function App() {
  const ScrollToTop = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  return (
    <>
    <Router>
      <ScrollToTop>
      <NavBar />
      <Gecko />      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/rooms" element={<Rooms />} />
        <Route path="/rooms/:id" element={<RoomDetail />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacidad" element={<Privacidad />} />
        <Route path="/avisoLegal" element={<AvisoLegal />} />
        <Route path="/reservas" element={<Reserva />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer />{" "}
      </ScrollToTop>
    </Router>

    <Toaster
    position="top-center"
    gutter={12}
    containerStyle={{ margin: "8px" }}
    toastOptions={{
      success: {
        duration: 2000,
        background: 'green',
        color: 'white',
      },
      error: {
        duration: 3000,
        background: 'red',
        color: 'white',
      },
      style: {
        fontSize: "16px",
        maxWidth: "500px",
        padding: "16px 24px",
      },
    }}
    />
    </>


  );
}

export default App;
library.add(fas, fab);

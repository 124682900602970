import { useTranslation } from "react-i18next";
import Title from "./Title";
import Carousel from "react-bootstrap/Carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, Navigate, useParams } from "react-router-dom";


const RoomDetail = () => {
    const { t } = useTranslation();
    const param = useParams();
  
    if(param.id === undefined) {
        return <Navigate to="/rooms"/>
    }

    return (
      <section className="section" id="rooms">
        <Link   
          to="/rooms"
          className="s-button backToRooms"
        >
        {t("roomsPhone")}
        </Link>
        <Link   
          to="/contact"
          className="s-button request"
        >
        {t("contactSubTitle")}
        </Link>
        <Title title={""} subTitle={t(`${param.id}.title_sub`)} />

        <div className="single">
            <div className="pic_desc">
                <div className="single2">
                    <Carousel fade>
                    {t(`${param.id}.images.items`, { returnObjects: true }).map((image) => (
                        <Carousel.Item key={image.id} interval={2500}>
                        <img
                            src={image.image}
                            alt="Pictures of Room"
                            className="d-block w-100"
                            />
                        </Carousel.Item>
                    ))}
                    </Carousel>
                </div> 
                <div className="single2">
                        <p className="singleRoom">{t(`${param.id}.info`)} </p>
                </div>
            </div>          
            <div className="single1">
                <ul className="singleRoom_items">                
                    <li style={{fontWeight: 'bold', paddingBottom: '10px'}}><h2><FontAwesomeIcon  icon={t(`${param.id}.kitchen.icon`)} /><span className="descspace">{t(`${param.id}.kitchen.title`)} </span></h2></li>
                        {t(`${param.id}.kitchen.items`, { returnObjects: true }).map((item) => ( 
                            <li key={item.id} >                        
                                <h6>{item.title}</h6>
                                <p>{item.desc}</p>
                            </li>                                   
                        ))}
                </ul>
            </div>
            <div className="single1">
                <ul className="singleRoom_items">                
                    <li style={{fontWeight: 'bold', paddingBottom: '10px'}}><h2><FontAwesomeIcon  icon={t(`${param.id}.living.icon`)} /><span className="descspace">{t(`${param.id}.living.title`)}</span></h2></li>
                        {t(`${param.id}.living.items`, { returnObjects: true }).map((item) => ( 
                            <li key={item.id} >                        
                                <h6>{item.title}</h6>
                                <p>{item.desc}</p>
                            </li>                                   
                        ))}
                </ul>
            </div>
            <div className="single1">                
                <ul className="singleRoom_items">                
                    <li style={{fontWeight: 'bold', paddingBottom: '10px'}}><h2><FontAwesomeIcon  icon={t(`${param.id}.internet.icon`)} /><span className="descspace">{t(`${param.id}.internet.title`)}</span></h2></li>
                        {t(`${param.id}.internet.items`, { returnObjects: true }).map((item) => ( 
                            <li key={item.id} >                        
                                <h6>{item.title}</h6>
                                <p>{item.desc}</p>
                            </li>                                   
                        ))}
                </ul>
            </div>
            <div className="single1">                
                <ul className="singleRoom_items">                
                    <li style={{fontWeight: 'bold', paddingBottom: '10px'}}><h2><FontAwesomeIcon  icon={t(`${param.id}.pricing.icon`)} /><span className="descspace">{t(`${param.id}.pricing.title`)}</span></h2></li>
                        {t(`${param.id}.pricing.items`, { returnObjects: true }).map((item) => ( 
                            <li key={item.id} >                        
                                <h6>{item.title}</h6>
                                <p>{item.desc1}</p>
                                <p>{item.desc2}</p>
                                <p>{item.desc3}</p>
                                <p>{item.desc4}</p>
                            </li>                                   
                        ))}
                </ul>
            </div> 
        </div>            
    </section>
    );
  };
  export default RoomDetail;
import { useTranslation } from "react-i18next";
import PageLinks from "./PageLinks";
import SocialLink from "./SocialLink";
import { Link, Outlet } from "react-router-dom";
const Footer = ({ parentClass, itemClass }) => {
  const { t } = useTranslation();
  return (
    <footer className="section footer">
      <PageLinks parentClass="footer-links" itemClass="footer-link" />
      <ul className="footer-icons">
        {t("socialLinks", { returnObjects: true }).map((link) => {
          return (
            <SocialLink
              key={link.id}
              itemClass="footer-icon"
              href={link.href}
              icon={link.icon}
            />
          );
        })}
      </ul>
      <p className="copyright">
        copyright &copy; {t("footerOwner")}
        <span id="date">{new Date().getFullYear()}</span>. all rights reserved
      </p>
      <ul className={parentClass} id="nav-links">
      <li>
      <Link to="/avisoLegal" className={itemClass} style={{textDecoration: "none"}}>
      {t("aviso.h2")}
      </Link>
      <Outlet />
    </li>
    <li>
      <Link to="/reservas" className={itemClass} style={{textDecoration: "none"}}>
      {t("reserva.h2")}
      </Link>
      <Outlet />
    </li>
      <li>
      <Link to="/privacidad" className={itemClass} style={{textDecoration: "none"}}>
      {t("privacidad.h2")}
      </Link>
      <Outlet />
    </li>
    <li>
      <Link to="/cookies" className={itemClass} style={{textDecoration: "none"}}>
      {t("cookies.h2")}
      </Link>
      <Outlet />
    </li>
    </ul>
    <div>

   <a href="https://www.flaticon.com/free-animated-icons/reptile" target="blank" title="reptile animated icons">Reptile animated icons created by Freepik - Flaticon</a>
    </div>
    </footer>
  );
};
export default Footer;

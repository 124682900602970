import { CTable, CTableBody, CTableCaption, CTableDataCell, CTableHead, CTableHeaderCell, CTableRow } from "@coreui/react";
import { useTranslation } from "react-i18next";

const Cookies = () => {   
    const { t } = useTranslation();
    return (
        
        <div className="impressum">
            <h2>{t("cookies.h2")}</h2><br/>
            <h4>{t("cookies.h4_1")}</h4>
            <p>{t("cookies.p_1")}<a href="https://www.boe.es/buscar/act.php?id=BOE-A-2002-13758" target="_blank" rel="noreferrer">{t("cookies.a_1")}</a>
            {t("cookies.p_2")}</p>
            <p>{t("cookies.p_3")}</p>
            <p>{t("cookies.p_4")}
            <a href="https://www.aepd.es/es/documento/guia-cookies.pdf" target="_blank" rel="noreferrer">{t("cookies.a_2")}</a>
            {t("cookies.p_5")}</p>
            
            <h4>{t("cookies.h4_2")}</h4>
            <p>{t("cookies.p_6")}</p>
            
            <ul>
                <li>{t("cookies.li_1")}</li>
            </ul>

            <p>{t("cookies.p_7")}</p>
            <ul>
                <li>{t("cookies.li_2")}</li>
            </ul>
            
            <p>{t("cookies.p_8")}</p>
            <ul>
                <li>{t("cookies.li_3")}</li>
            </ul>

            <h4>{t("cookies.h4_3")}</h4>
            <p>{t("cookies.p_9")}</p>
            <p>{t("cookies.p_10")}</p>


            <h4>{t("cookies.h4_4")}</h4><br/>
            <CTable caption="top" responsive bordered>
                <CTableCaption>{t("cookies.t_1")}</CTableCaption>
                <CTableHead class="border border-info">
                    <CTableRow>
                        <CTableHeaderCell>{t("cookies.th_1")}</CTableHeaderCell>
                        <CTableHeaderCell>{t("cookies.th_2")}</CTableHeaderCell>
                        <CTableHeaderCell>{t("cookies.th_3")}</CTableHeaderCell>
                        <CTableHeaderCell>{t("cookies.th_4")}</CTableHeaderCell>
                        <CTableHeaderCell>{t("cookies.th_5")}</CTableHeaderCell>
                        <CTableHeaderCell>{t("cookies.th_6")}</CTableHeaderCell>
                    </CTableRow>
                </CTableHead>
                <CTableBody class="border border-info">
                    <CTableRow>
                        <CTableDataCell>{t("cookies.td_1")}</CTableDataCell>
                        <CTableDataCell>{t("cookies.td_2")}</CTableDataCell>
                        <CTableDataCell>{t("cookies.td_3")}</CTableDataCell>
                        <CTableDataCell>{t("cookies.td_4")}</CTableDataCell>
                        <CTableDataCell>{t("cookies.td_5")}</CTableDataCell>
                        <CTableDataCell>{t("cookies.td_6")}</CTableDataCell>
                    </CTableRow>
                </CTableBody>
            </CTable> <br/>

            <h4>{t("cookies.h4_5")}</h4>
            <p>{t("cookies.p_11")}</p>
            <p>{t("cookies.p_12")}</p>

            <h4>{t("cookies.h4_6")}</h4>
            <p>{t("cookies.p_13")}</p>

            <h4>{t("cookies.h4_7")}</h4>
            <ul>
                <li><a href="http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647" target="_blank" rel="noreferrer">{t("cookies.a_3")}</a></li>
                <li><a href="http://support.mozilla.org/es/kb/configuracion-de-la-privacidad-el-historial-de-nav?redirectlocale=es&as=u&redirectslug=Ventana+Opciones+-+Panel+Privacidad#w_rastrear" target="_blank" rel="noreferrer">{t("cookies.a_4")}</a></li>
                <li><a href="http://windows.microsoft.com/es-xl/internet-explorer/delete-manage-cookies#ie=ie-11" target="_blank" rel="noreferrer">{t("cookies.a_5")}</a></li>
                <li><a href="http://support.apple.com/kb/PH5042" target="_blank" rel="noreferrer">{t("cookies.a_6")}</a></li>
                <li><a href="https://support.microsoft.com/es-es/help/11696/windows-phone-7" target="_blank" rel="noreferrer">{t("cookies.a_7")}</a></li>
                <li><a href="https://support.apple.com/es-es/HT201265" target="_blank" rel="noreferrer">{t("cookies.a_8")}</a></li>
                <li><a href="https://support.google.com/chrome/answer/95647?hl=es&co=GENIE.Platform%3DAndroid&oco=1" target="_blank" rel="noreferrer">{t("cookies.a_9")}</a></li>
            </ul>

            <h4>{t("cookies.h4_8")}</h4>
            <ul>
                <li><a href="https://es-la.facebook.com/help/instagram/402411646841720" target="_blank" rel="noreferrer">{t("cookies.a_10")}</a></li>
                <li><a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">{t("cookies.a_11")}</a></li>
                <li><a href="https://help.twitter.com/es/rules-and-policies/twitter-cookies" target="_blank" rel="noreferrer">{t("cookies.a_12")}</a></li>
                <li><a href="https://vimeo.com/cookie_policy" target="_blank" rel="noreferrer">{t("cookies.a_13")}</a></li>
                <li><a href="https://www.linkedin.com/legal/cookie-policy?_l=es_ES" target="_blank" rel="noreferrer">{t("cookies.a_14")}</a></li>
                <li><a href="https://policy.pinterest.com/es/cookies" target="_blank" rel="noreferrer">{t("cookies.a_15")}</a></li>
                <li><a href="https://es-la.facebook.com/msqrd/privacy" target="_blank" rel="noreferrer">{t("cookies.a_16")}</a></li>
            </ul><br/>
            

            <h4>{t("cookies.h4_9")}</h4>
            <p>{t("cookies.p_14")}</p>
        
        <h4>{t("cookies.h4_10")}</h4>
        <p>{t("cookies.p_15")}</p>
        
        <h4>{t("cookies.h4_11")}</h4>
        <p>{t("cookies.p_16")}
        <a href="https://www.aepd.es/es/documento/guia-cookies.pdf" target="_blank" rel="noreferrer">{t("cookies.a_17")}</a>
        {t("cookies.p_17")}</p><br/>

        <p>{t("cookies.p_18")}</p>
        
        </div>
        );
};
export default Cookies;
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Reserva = () => {
    const { t } = useTranslation();
    return (
        
        <div className="impressum">
            <h2>{t("reserva.h2")}</h2><br/>
           
            <p>{t("reserva.p_1")}
                <a href="https://www.boe.es/buscar/act.php?id=BOE-A-2002-13758" target="_blank" rel="noreferrer">
                {t("reserva.a_1")}</a> 
            {t("reserva.p_2")}</p>
            <p>{t("reserva.p_3")}
                <Link to="/cookies">{t("reserva.link_1")}</Link>
                {t("reserva.p_4")}
                <Link to="/privacidad">{t("reserva.link_2")}</Link>
                {t("reserva.p_5")}
                <Link to="/avisoLegal">{t("reserva.link_3")}</Link>.</p>
            
            <h4>{t("reserva.h4_1")}</h4>
            <p>{t("reserva.p_6")}</p>

            <h4>{t("reserva.h4_2")}</h4>
            <p>{t("reserva.p_7")}</p>

            <p>{t("reserva.p_8")}</p>
            <ul>
                <li>{t("reserva.li_1")}</li>
                <li>{t("reserva.li_2")}</li>
                <li>{t("reserva.li_3")}</li>
            </ul>

            <h4>{t("reserva.h4_3")}</h4>
            <p>{t("reserva.p_9")}</p>

            <h4>{t("reserva.h4_4")}</h4>
            <p>{t("reserva.p_10")}</p>
            <p>{t("reserva.p_11")}</p>
        
            <h4>{t("reserva.h4_5")}</h4>
            <p>{t("reserva.p_12")}</p>

            <h4>{t("reserva.h4_6")}</h4>
            <p>{t("reserva.p_13")}</p>
            <p>{t("reserva.p_14")}</p>

            <h4>{t("reserva.h4_7")}</h4>
            <p>{t("reserva.p_15")}</p>
            <ul>
                <li>{t("reserva.li_4")}</li>
                <li>{t("reserva.li_5")}</li>
            </ul>
            <p>{t("reserva.p_16")}</p>
            <ul>
                <li>{t("reserva.li_6")}</li>
                <li>{t("reserva.li_7")}</li>
                <li>{t("reserva.li_8")}</li>
                <li>{t("reserva.li_9")}</li>
                <li>{t("reserva.li_10")}</li>
            </ul>

            <h4>{t("reserva.h4_8")}</h4>
            <p>{t("reserva.p_17")}
            <a href="https://www.boe.es/doue/2013/165/L00001-00012.pdf" target="_blank" rel="noreferrer">{t("reserva.a_2")}</a>
            {t("reserva.p_18")}
            <a href="https://www.boe.es/doue/2013/165/L00063-00079.pdf" target="_blank" rel="noreferrer">{t("reserva.a_3")}</a>
            {t("reserva.p_19")}</p>
            <p>{t("reserva.p_20")}
            <a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage" target="_blank" rel="noreferrer">{t("reserva.a_4")}</a>
            </p>
            <p>{t("reserva.p_21")}</p>

            <h4>{t("reserva.h4_9")}</h4>
            <ul>
                <li>{t("reserva.li_11")}
                <Link to="/avisoLegal">{t("reserva.link_4")}</Link>
                </li>
                <li>{t("reserva.li_12")}
                <Link to="/privacidad">{t("reserva.link_5")}</Link>
                </li>
                <li>{t("reserva.li_13")}
                <Link to="/cookies">{t("reserva.link_6")}</Link>
                </li>
            </ul>
            
            <h4>{t("reserva.h4_10")}</h4>
            <p>{t("reserva.p_22")}</p>

            <h4>{t("reserva.h4_11")}</h4>
            <p>{t("reserva.p_23")}</p>

            <p>{t("reserva.p_24")}</p>

        </div>
        );
};
export default Reserva;
/* import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; */
/* import { useTranslation } from "react-i18next";
 */
const Room = ({ image, title, info, location, duration, id }) => {
  /* const {t}= useTranslation(); */
  return (
    <article key={id} className="room-card">

          <div className="tour-img-container">
            <img src={image} className="tour-img" alt={title} />
            {/* <p className="tour-date">
              {<FontAwesomeIcon icon="fa-solid fa-house" />}
            </p> */}
          </div>
          <div className="tour-info">
            <div className="tour-title">
              <h4>{title}</h4>
            </div>
            <p>{info}</p>
            <br />
            <div className="tour-footer">
              <p>{location}</p>
              <p>{duration}</p>
            </div>
          </div>
        
    </article>
  );
};
export default Room;

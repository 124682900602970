import { useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import i18n from "../lang/i18n";
import "./NavBar.css";

const LangSelect = (properties) => {
  const [country, setCountry] = useState(readI18n(i18n.language));

  function newCountryAndLang(code) {
    setCountry(code);

    if (code === "DE") {
      i18n.changeLanguage("de");
    } else if (code === "ES") {
      i18n.changeLanguage("es");
    } else {
      i18n.changeLanguage("en");
    }
  }

  function readI18n(lng) {
    lng = lng.toLowerCase();

    if (lng === "de" || lng.startsWith("de-")) {
      return "DE";
    } else if (lng === "es" || lng.startsWith("es-")) {
      return "ES";
    } else {
      return "GB";
    }
  }

  return (
    <div className="flags-container">
      <div></div>
      <div>
        <ReactFlagsSelect
          className="menu-flags"
          countries={["DE", "GB", "ES"]}
          customLabels={{ DE: "DE", GB: "EN", ES: "ES" }}
          selected={country}
          onSelect={(code) => {
            newCountryAndLang(code);
            properties.parentClick();
          }}
          optionsSize={20}
          selectButtonClassName="react-flags-select"
        />
      </div>
      <div></div>
    </div>
  );
};
export default LangSelect;

import aboutImg from "../images/Anlage.jpg";
import Title from "./Title";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  return (
    <section className="section" id="about">
      <Title title={t("aboutTitle")} subTitle={t("aboutSubTitle")} />

      <div className="section-center about-center">
         <div className="about-img">
          <img src={aboutImg} className="about-photo" alt="Bild mit Blick auf den Pool" />
                
      </div>
        <article className="about-info">
          <h3>{t("aboutTrainer")}</h3>
          <p>{t("aboutPresent")}</p>
          <p>{t("aboutDescription")}</p>
        </article>
      </div>
    </section>
  );
};
export default About;

import Title from "./Title";
import Room from "./Room";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Rooms = () => {
  const { t } = useTranslation();

  return (
    <section className="section" id="rooms">
      <Title title={t("roomsTitle")} subTitle={t("roomsSubTitle")} />

      <div className="section-center featured-center">
        {t("room.items", { returnObjects: true }).map((item) => {
          return (
            
            <Link key={item.id} className="room-link" to={`/rooms/${item.id}`}>
              <Room {...item} />
            </Link>
            
          );
          
        })}
      </div>
    </section>
  );
};
export default Rooms;

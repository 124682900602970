import { useTranslation } from "react-i18next";
const Privacidad = () => {
    const { t } = useTranslation();
    return (
        <div className="impressum">
            <h2>{t("privacidad.h2")}</h2><br/>
            <h4>{t("privacidad.h4_1")}</h4>
            <p>{t("privacidad.p_1")}</p>

            <h4>{t("privacidad.h4_2")}</h4>
            <p>{t("privacidad.p_2")}
            <a href="https://www.boe.es/doue/2016/119/L00001-00088.pdf" target="_blank" rel="noreferrer">{t("privacidad.a_1")}</a>
            {t("privacidad.p_2_1")}
            <a href="https://www.boe.es/boe/dias/2018/12/06/pdfs/BOE-A-2018-16673.pdf" target="_blank" rel="noreferrer">{t("privacidad.a_2")}</a>
            {t("privacidad.p_2_2")}
            <a href="https://www.boe.es/buscar/act.php?id=BOE-A-2002-13758" target="_blank" rel="noreferrer">{t("privacidad.a_3")}</a>
            {t("privacidad.p_2_3")}</p>

            <h4>{t("privacidad.h4_3")}</h4>
            <p>{t("privacidad.p_3")}</p>

            <h4>{t("privacidad.h4_4")}</h4>
            <p>{t("privacidad.p_4")}</p>

            <h4>{t("privacidad.h4_5")}</h4>
            <p>{t("privacidad.p_5")}</p>

            <h4>{t("privacidad.h4_6")}</h4>
            <p>{t("privacidad.p_6")}</p>
        
            <h4>{t("privacidad.h4_7")}</h4>
            <p>{t("privacidad.p_7")}</p>

            <h4>{t("privacidad.h4_8")}</h4>
            <p>{t("privacidad.p_8")}</p>
            <p>{t("privacidad.p_9")}</p>
        
            <h4>{t("privacidad.h4_9")}</h4>
            <p>{t("privacidad.p_10")}</p>
        
            <h4>{t("privacidad.h4_10")}</h4>
            <p>{t("privacidad.p_11")}</p>        
            <p>{t("privacidad.p_12")}</p>
            <p>{t("privacidad.p_13")}</p>
            <p>{t("privacidad.p_14")}</p>
            <p>{t("privacidad.p_15")}</p>
            <p>{t("privacidad.p_16")}</p>
            <p>{t("privacidad.p_17")}</p>
            <p>{t("privacidad.p_18")}</p>

            <p>{t("privacidad.p_19")}
            <a href="https://www.aepd.es/derechos-y-deberes/conoce-tus-derechos" target="_blank" rel="noreferrer">{t("privacidad.a_4")}</a>
            {t("privacidad.p_19_1")}</p>

            <h4>{t("privacidad.h4_11")}</h4>
            <p>{t("privacidad.p_20")}
            <a href="https://www.aepd.es/" target="_blank" rel="noreferrer">{t("privacidad.a_5")}</a>
            {t("privacidad.p_20_1")}</p>
        
            <h4>{t("privacidad.h4_12")}</h4><br/>
            <h4>{t("privacidad.h4_13")}</h4>
            <p>{t("privacidad.p_21")}</p>

            <h4>{t("privacidad.h4_14")}</h4>
            <p>{t("privacidad.p_22")}</p>
            <p>{t("privacidad.p_23")}</p>
            <p>{t("privacidad.p_24")}</p>
            <p>{t("privacidad.p_25")}</p>
            <p>{t("privacidad.p_26")}</p>

            <h4>{t("privacidad.h4_15")}</h4>
            <p>{t("privacidad.p_27")}
            <a href="https://www.boe.es/buscar/act.php?id=BOE-A-2002-13758" target="_blank" rel="noreferrer">{t("privacidad.a_6")}</a>
            {t("privacidad.p_28")}</p>
            <p>{t("privacidad.p_29")}</p>
            <ul>
                <li>{t("privacidad.li_1")}</li>
                <li>{t("privacidad.li_2")}</li>
            </ul>

            <h4>{t("privacidad.h4_16")}</h4>
            <p>{t("privacidad.p_30")}</p>

            <h4>{t("privacidad.h4_17")}</h4>
            <p>{t("privacidad.p_31")}</p>

            <p>{t("privacidad.p_32")}</p>
        </div>
        );
};
export default Privacidad;
import { Outlet, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Home.css";

const Home = () => {
  const { t } = useTranslation();
  return (
    <section className="hero" id="home">
      <div className="hero-banner">
        <h1 className="hero-title">{t("homeTitle")}</h1>
        <p className="home-subtitle">{t("homeDescription")}</p>
        <Link
          to="/rooms"
          className="home-s-button"
        >
        {t("homeLink")}
        </Link>
        <Outlet />
      </div>
    </section>
  );
};
export default Home;

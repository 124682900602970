
import { useTranslation } from "react-i18next";
const AvisoLegal = () => {
    const { t } = useTranslation();
    return (
        
        <div className="impressum">
            <h2>{t("aviso.h2")}</h2><br/>
            <h4>{t("aviso.h4_1")}</h4>
            <p>{t("aviso.p_1")}</p>
            <p>{t("aviso.p_2")} <a href="https://www.boe.es/buscar/act.php?id=BOE-A-2002-13758" target="_blank" rel="noreferrer">{t("aviso.a_1")}</a>{t("aviso.p_2_1")}</p>
            <ul>
                <li>{t("aviso.li_1")}</li>
                <li>{t("aviso.li_2")}</li>
                <li>{t("aviso.li_3")}</li>
                <li>{t("aviso.li_4")}</li>
                <li>{t("aviso.li_5")}</li>
            </ul>
            
            <p>{t("aviso.p_3")}</p>
            <ul>
                <li><a href="https://www.gobiernodecanarias.org/boc/2015/101/001.html" target="_blank" rel="noreferrer">{t("aviso.a_2")}</a>{t("aviso.li_6")}</li>
            </ul>

            <h4>{t("aviso.h4_2")}</h4>
            <p>{t("aviso.p_4")}</p>
            <p>{t("aviso.p_5")}</p>

            <h4>{t("aviso.h4_3")}</h4>
<br/>
            <h4>{t("aviso.h4_4")}</h4>
            <p>{t("aviso.p_6")}</p>
            <p>{t("aviso.p_7")}</p>
            <p>{t("aviso.p_8")}</p>
            <p>{t("aviso.p_9")}</p>

            <h4>{t("aviso.h4_5")}</h4>
            <p>{t("aviso.p_10")}</p>

            <h4>{t("aviso.h4_6")}</h4>
            <p>{t("aviso.p_11")}</p>

            <h4>{t("aviso.h4_7")}</h4>
            <p>{t("aviso.p_12")}</p>
            <p>{t("aviso.p_13")}</p>
            
        </div>
        );
};
export default AvisoLegal;
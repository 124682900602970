import PageLink from "./PageLink";
import { useTranslation } from "react-i18next";

const PageLinks = ({ parentClass, itemClass }) => {
  const { t } = useTranslation();
  return (
    <ul className={parentClass} id="nav-links">
      {t("pageLinks", { returnObjects: true }).map((link) => {
        return <PageLink key={link.id} link={link} itemClass={itemClass} />;
      })}
    </ul>
  );
};
export default PageLinks;

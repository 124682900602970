import { initReactI18next } from "react-i18next";
import { LANG_DE } from "./lang_de";
import { LANG_EN } from "./lang_en";
import { LANG_ES } from "./lang_es";
import LanguageDetector from "i18next-browser-languagedetector";
import i18n from "i18next";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      de: {
        translation: LANG_DE,
      },
      en: {
        translation: LANG_EN,
      },
      es: {
        translation: LANG_ES,
      },
    },

    fallbackLng: "de",
  });

export default i18n;
